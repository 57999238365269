import React from "react"
import Layout from '../containers/layout/layout'
import SEO from "../components/seo"
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import {Container} from '../components/ui/wrapper/container';
import {Row} from '../components/ui/wrapper/row';
import {Col} from '../components/ui/wrapper/col';
import SectionTitle from '../components/ui/section-title';

const TermsOfPayment = ({pageContext, location}) => (
  <Layout location={location}>
    <SEO title="Terms of payment" />
    <Header/>
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Terms and Conditions"
    />
    <main className="site-wrapper-reveal">
      <Container>
        <Row mt="40px" mb="350px">
          <Col lg={12}>
            <SectionTitle
              mb="40px"
              title="Terms and Conditions"
              subtitle="This is"
            />
          </Col>
          <Col lg={8} className="pl-5 pr-5">
             <span>Last updated: December 10,2020</span>

            <p> Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the triploaf.com website and the operated by “TRIPLOAF” MRGV ("us", "we", or "our").
Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
Understanding who Triploaf is</p>

<p>MRGV is the company responsible for these Terms and Conditions (Referred to as “TRIPLOAF”, “we”, “us” or “our” in these Terms). </p>

<p>MRGV is made up of different entities. These Terms and Conditions are issued on behalf of MRGV so when we mention “TRIPLOAF”, “we”, “us” or “our” in this Terms, we are referring to the relevant company in MRGV responsible for processing your purchase. </p>

<p>Contact details:</p>

<p>Full Name of Entity: MRGV </p>

<p>Email address: <b>hello@mrgv.co</b> </p>
 
<p><h5>Our relationship with you </h5></p>
<p>We are an online travel agency and travel content provider. Our content covers people, places and culture around the world. We offer the ability to purchase accommodations, experiences, flights and other services related to travel via our Site. These products and services are provided by third party suppliers (for example hotels, tour operations and others). Here now referred to as “Supplier(s)”. 
We do not directly provide any of the products or services to you, we act as an agent on behalf of the Suppliers and as an intermediary between you and the Supplier. As a result, the Supplier is ultimately responsible for supplying the service or product to you. 
When you book a service via our Site, the legally binding relationship is between you and the relevant Supplier under their Terms and Conditions. These Terms will be provided to you during the booking process and as a part of the booking confirmation.
In addition to the Supplier’s terms, our own Terms and Conditions here presented also apply to your booking. </p>
<p><h5>Our Services </h5></p>
<p>As part of our services, we offer you the ability to search through the Site to find travel journeys, flights, accommodations and services, then book and purchase directly through our Site. 
We will act as an intermediary between you and the Supplier that you have purchased a service from when you have any questions, changes and/or issues with a booking. We offer this service through our customer support centre as a gesture of goodwill and we will help to fulfill any requests and/or resolve any issues with a booking but we cannot guarantee or be responsible for the actual provision of the service from the Supplier. You can contact our customer support centre about a pending booking and/or existing booking at the following email address: <b>hello@mrgv.co</b> </p>
<p><h5>Booking a service</h5></p>
<p>To successfully submit a booking you need to follow the booking path available on our Site. The Site allows you to check your order and correct any errors made by you during the booking process before completing a purchase. Please take the time to read and check your order at each page of the booking process as you are responsible for: (a) ensuring that the information you provide is accurate; and (b) ensuring that you are eligible for participating in and booking.  
By using our services and making a booking for travel services through our Site, you confirm that you accept these Terms and confirm that you:
Are 18 years of age or older;
you possess authority to enter into these Terms, book the travel service and use the Site;
all information you supply is true and accurate;
you are authorised to use the credit or debit card that you use to pay for the Service(s); and
you will use the booking functions within the Site to only make legitimate reservations for you and/or others for whom you are authorised to act. False and/or fraudulent reservations are strictly prohibited and if you attempt such a reservation you may have your account terminated.</p>
<p><h5>Price and payment</h5></p>
<p>The sale price of each travel service indicated on the Site includes service fees and applicable sales taxes such as VAT ("the Sale Price"). Any city taxes or other mandatory fees charged by the Accommodation such as resort fees, will be as notified to you during the booking process and then charged by the Accommodation on check-in or check-out.
By completing the check-out process and making a booking for travel service by clicking the "Pay" button on the checkout page, you are confirming to purchase the travel service from the relevant Supplier. All reservations are only confirmed when you receive the booking confirmation from us. If you do not receive a booking confirmation within 48 hours of placing your reservation please contact our customer support centre at <b>hello@mrgv.co</b> 
Payments may be subject to exchange-rate fluctuations (depending on the date charged) and charges from your bank or credit card issuer for which we have no liability. Payments on our Site are processed by Stripe. 
We will accept payment from you in accordance with the payment methods listed on our Site. 
We reserve the right to amend all advertised prices on the Site. We also reserve the right to correct errors in pending bookings and any obvious errors or mistakes in prices of pending bookings and confirmed bookings.</p>
<p><h5>Cancellations and refund </h5></p>
<p>In all cases, once a booking is confirmed with the Supplier you are subject to the Supplier’s cancellation policy which will be included in your booking confirmation. 
If you wish to amend or cancel a confirmed booking, you may contact us at hellp@mrgv.co Amendments and cancellations can only be accepted in accordance with the Supplier’s Terms, your booking confirmation will also include details on the cancellation policy. 
Cancellations made by the Supplier or us. If a Supplier needs to either cancel or make a significant change to your confirmed booking of a travel service, we will let you know as soon as possible. If time allows before the start of your travel service, we will, using reasonable efforts, liaise between you and the Supplier and aim to obtain the following options from the Supplier for you to choose from either:
accepting an alternative reservation; or cancelling your booking together with a full refund of all monies you have paid for your booking; or
accepting the changes made by the Supplier. We reserve the right to make changes, cancel or substitute the travel service if the Supplier needs to make the change, cancel or substitute. We act as an agent on behalf of the SUpplier and your contract for the travel service is with the Supplier, we will not be responsible for any costs associated with a cancellation or significant change made by a Supplier. 
Where refunds are applicable, they will be made by the same method used to pay for the booking. Any payment made by credit card or debit card will be returned to that card and will not be refunded to any other card. </p>
<p><h5>Travel Documentation</h5></p>
<p>You are responsible for all travel and/or transport at your own cost and risk, this includes ensuring you meet all foreign entry requirements, country related requirements, passport related requirements and visa related requirements. Requirements can change at any time and so we encourage you to ensure you check the up to date position with the your local passport office, the relevant embassy, consulate, doctor, with the Supplier in advance of departure and the relevant government’s website for the latest advice on conditions and information on specific countries.
We strongly recommend that you purchase comprehensive and relevant insurance for the travel service as soon as you purchase the travel service through our Site. The insurance should cover, amongst other things, the cost of cancellation by you, personal losses, all medical costs and the costs of assistance including return to your country of residence in the event of an accident and/or illness.</p>

<p><h5>Travel Journeys </h5></p>
<p>We do not sell connected travel packages and as a result you will not benefit from rights related to missed travel itineraries based on connected travel packages. 
If you purchase a travel journey with individual experiences, your purchased services will have independent Supplier contracts and Terms. </p>
<p><h5>Liability</h5></p>
<p>As an agent, we accept no liability for the actual provision of the travel service. Our responsibilities and liability to you is limited to making the booking for the travel service with the Supplier in accordance with your instructions. In addition to this, as a gesture of goodwill, we will act as an intermediary between you and the Supplier through our customer support centre when you have any questions, changes or issues but you accept that we cannot guarantee, be responsible for or be liable for the travel service that you have booked with the Supplier.
We accept no liability for: </p>
<p>you and/or any member of your booking being refused entry into any country due to failure to hold the correct passport, visa or any other travel documentation that is required by any country, authority or airline;
any articles, photos and/or videos on our Site that relate to the travel service not meeting your expectations. The articles, photos and/or videos we make available on our Site are intended to give a guide to the services and/or facilities you may expect from the travel service but we must not be considered to be making any representations or warranties and cannot guarantee the accuracy of any information provided by us through articles, photos and/or videos on our Site;
 any errors, inaccuracies and/or omissions, Sale Prices or other information provided by Suppliers; and
any system (which includes the Platform) failure of any kind.</p>

<p><h5>Complaints and Customer Support</h5></p>
<p>As already mentioned in these Terms, the contract for your booking of a travel service is between you and the Supplier. We are happy to act as an intermediary between you and the supplier. If you have an existing booking question please get in touch with <b>hello@mrgv.co</b> </p>


 
 
 
 
 
 
 
 


         
          </Col>
        </Row>
      </Container>
    </main>
    <Footer/>
  </Layout>
)

export default TermsOfPayment;
